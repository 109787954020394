<template>
  <base-section id="core-competencies">
    <v-container>
      <v-row>
        <v-col
          cols="2"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
            />

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="PRODUCTS AND SUPPORT"
            space="0"
            align="center"
          />
          <br>
          <v-row>
            <v-col
              v-for="(text, i) in products"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCoreCompetencies',

    data: () => ({
      cards: [
        {
          title: 'Corporate Products',
          icon: 'mdi-briefcase-outline',
          outlined: true,
        },
      ],
      products: [
        'Acrylonitrile Butadiene for glove applications',
        'Styrene Butadiene and other latex for specialty applications',
        'Company identification and selection',
        'Outsourcing and manufacturing footprint support',
        'Partnership development',
        'Investment opportunities',
        'Contract negotiation support',
        'Technology development and acquisition',
        'Communication Support',
        'Business development support',
      ],
    }),
  }
</script>
